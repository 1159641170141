import React from 'react'
import {LanguageType} from '../../types/LanguageType'
import {StateType} from '../../types/StateType'
import {connect} from 'react-redux'

const Language = (props: Props) => {
  const {flag, slug, language} = props

  return (
    <span className="cursor">
      <img src={`https://lipis.github.io/flag-icon-css/flags/4x3/${flag}.svg`} width="25" className="m-r-5"/>
      {language.dictionary[slug]}
    </span>
  )
}


type Props = {
  language: LanguageType
  flag: string
  slug: string
}

const mapStateToProps = (state: StateType) => {
  return {
    language: state.language
  }
}

export default connect(mapStateToProps)(Language)
