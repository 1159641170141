export const SHOW_DRAWER = 'SHOW_DRAWER'
export const HIDE_DRAWER = 'HIDE_DRAWER'

export function showDrawer(component: any, placement = 'right') {
  return {
    type: SHOW_DRAWER,
    payload: {
      show: true,
      placement,
      component
    },
  }
}

export function hideDrawer() {
  return {
    type: HIDE_DRAWER,
    payload: {
      show: false
    },
  }
}
