import * as React from 'react'
import {Drawer as DrawerRS} from 'rsuite'

export interface IProps {
  full?: boolean;
  title?: any;
  body: any;
  show: boolean;
  size?: any; // "xs", "sm", "md", "lg"
  placement?: any; // "left", "right", "top", "bottom"
  backdrop?: any; // "true", "false (statick without opacity)", "static",
  close: () => any;
  className: string
}

export default class Drawer extends React.Component<IProps> {
  render() {
    const {size, full, placement, show, close, title, body, backdrop, className} = this.props
    return (
      <div>
        <DrawerRS
          full={full}
          size={size}
          placement={placement}
          show={show}
          backdrop={backdrop}
          onHide={() => close()}
          className={className}
        >
          <DrawerRS.Header>
            {title && <DrawerRS.Title>{title}</DrawerRS.Title>}
          </DrawerRS.Header>

          {body && <DrawerRS.Body>{body}</DrawerRS.Body>}
        </DrawerRS>
      </div>
    )
  }

  static defaultProps = {
    size: 'xs',
    className: '',
    placement: 'right',
    backdrop: true,
    full: false,
  }
}
