import {
  LANDMARK_SELECTED,
  LANDMARKS_ERROR,
  LANDMARKS_LOAD, LANDMARKS_SEARCH,
  LANDMARKS_SUCCESS
} from '../actions/landmarks'
import {HIDE_DRAWER} from '../actions/show-drawer'
import {Constants} from '../config/Constants'
import {LandmarksType} from '../types/LandmarksType'

const initialState = {
  spinner: false,
  data: [],
  errors: null,
  landmark: null,
  search: ''
}

export default (state = initialState, action: any): LandmarksType => {
  switch (action.type) {
  case LANDMARKS_LOAD:
    return {
      ...state,
      data: [],
      spinner: true
    }
  case LANDMARKS_SUCCESS:
    return {
      ...state,
      spinner: false,
      data: action.payload.data
    }
  case LANDMARKS_ERROR:
    return {
      ...state,
      spinner: false,
      data: [],
      errors: action.payload.errors
    }
  case LANDMARK_SELECTED:
    return {
      ...state,
      landmark: action.payload.landmark
    }
  case LANDMARKS_SEARCH:
    return {
      ...state,
      search: action.payload.search
    }
  case HIDE_DRAWER:
    if(window.innerWidth <= Constants.RESOLUTION_MOVIL) {
      return {
        ...state,
        landmark: null
      }
    }

    return state
  default:
    return state
  }
}
