import {
  GEOLOCATE_ACTIVE,
  GEOLOCATE_CHANGE_PERFIL,
  GEOLOCATE_DISABLED,
  GEOLOCATE_VIEW_PERFIL
} from '../actions/geolocate'
import {Constants} from '../config/Constants'

const initialState = {
  active: false,
  perfil: Constants.MAP_PERFIL_DEFAULT,
  view: false
}

export default (state = initialState, action: any) => {
  switch (action.type) {
  case GEOLOCATE_ACTIVE:
    return {
      ...state,
      active: true
    }
  case GEOLOCATE_DISABLED:
    return {
      ...state,
      active: false
    }
  case GEOLOCATE_CHANGE_PERFIL:
    return {
      ...state,
      perfil: action.payload.perfil
    }
  case GEOLOCATE_VIEW_PERFIL:
    return {
      ...state,
      view: action.payload.view
    }
  default:
    return state
  }
}
