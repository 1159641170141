import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import Store from './store/Store'
import {landmarksLoadInit} from './actions/landmarks'

Store.dispatch(landmarksLoadInit())

ReactDOM.render(
  <Provider store={Store}>
    <App/>
  </Provider>, document.getElementById('root'))
