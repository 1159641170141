export const Constants = {
  LANGUAGE_DEFAULT: 'es',
  LANGUAGES: [
    {
      code: 'es',
      flag: 'es',
      slug: 'SPANISH'
    }, {
      code: 'en',
      flag: 'us',
      slug: 'ENGLISH'
    }, {
      code: 'pt',
      flag: 'br',
      slug: 'PORTUGUESE'
    },
  ],
  RESOLUTION_MOVIL: 992,
  MAP_PERFIL_DEFAULT: 'walking', //driving, walking, cycling
  CATEGORY_LANDMARK: 3,
  PLACES: {
    PUBLIC: 2
  }
}
