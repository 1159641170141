import React from 'react'
import {Whisper} from 'rsuite'
import MenuPopover from './MenuPopover'
import Language from './Language'
import {LanguageType} from '../../types/LanguageType'
import {Functions} from '../../commons/Functions'
import {connect} from 'react-redux'
import {StateType} from '../../types/StateType'
import {changeLanguage} from '../../actions/languages'
import LocalStorage from '../../commons/LocalStorage'
import {landmarkSelected, landmarksLoadInit} from '../../actions/landmarks'
import EventEmitter from '../../commons/EventEmitter'
import {Events} from '../../config/Events'

const Languages = (props: Props) => {
  const {handleChangeLanguage, language} = props
  const triggerRef: any = React.createRef()

  const handleSelectLanguage = (code: string) => {
    LocalStorage.setItem('language', code)
    handleChangeLanguage(code)
    triggerRef.current.hide()
  }

  return (
    <div className="p-r-20">
      <Whisper
        placement="bottomEnd"
        trigger="click"
        triggerRef={triggerRef}
        speaker={<MenuPopover onSelect={handleSelectLanguage}/>}
      >
        <div>
          {language && <Language flag={language.flag} slug={language.slug}/>}
        </div>
      </Whisper>
    </div>
  )
}

type Props = {
  language: LanguageType
  handleChangeLanguage: (code: string) => void
}

const mapStateToProps = (state: StateType) => {
  return {
    language: state.language
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleChangeLanguage: (code: string) => {
      const lang = Functions.getLanguage(code)
      if(lang) {
        dispatch(changeLanguage(lang))
        dispatch(landmarksLoadInit())
        EventEmitter.dispatch(Events.MAP_CLOSE_POPUP)
        dispatch(landmarkSelected(null))
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Languages)
