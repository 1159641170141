export const GEOLOCATE_ACTIVE = 'GEOLOCATE_ACTIVE'
export const GEOLOCATE_DISABLED = 'GEOLOCATE_DISABLED'
export const GEOLOCATE_CHANGE_PERFIL = 'GEOLOCATE_CHANGE_PERFIL'
export const GEOLOCATE_VIEW_PERFIL = 'GEOLOCATE_VIEW_PERFIL'

export function geolacateActive() {
  return {
    type: GEOLOCATE_ACTIVE,
    payload: {}
  }
}

export function geolacateDisabled() {
  return {
    type: GEOLOCATE_DISABLED,
    payload: {}
  }
}

export function geolacateChangePerfil(perfil: string) {
  return {
    type: GEOLOCATE_CHANGE_PERFIL,
    payload: {perfil}
  }
}

export function geolacateViewPerfil(view: boolean) {
  return {
    type: GEOLOCATE_VIEW_PERFIL,
    payload: {view}
  }
}
