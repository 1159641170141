import React, {Component} from 'react'
import {Button, ButtonGroup, Icon, IconButton} from 'rsuite'
import {LanguageType} from '../../types/LanguageType'
import {StateType} from '../../types/StateType'
import {connect} from 'react-redux'
import {hideDrawer, showDrawer} from '../../actions/show-drawer'
import References from '../references/References'
import {LinearProgress} from '@material-ui/core'
import {LandmarkType} from '../../types/LandmarkType'
import {landmarkSelected} from '../../actions/landmarks'
import {Constants} from '../../config/Constants'
import {geolacateActive, geolacateChangePerfil, geolacateDisabled, geolacateViewPerfil} from '../../actions/geolocate'
import Map from '../../lib/Map'
import InfoWindowMobil from '../info-window-mobil/InfoWindowMobil'
import EventEmitter from '../../commons/EventEmitter'
import {Events} from '../../config/Events'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWalking, faBiking, faCar, faTimes} from '@fortawesome/free-solid-svg-icons'
import {withSnackbar} from 'notistack'
import * as FileSaver from 'file-saver'

class Content extends Component<any, any> {
  private map: Map = new Map()
  private mapContainer: any

  componentDidMount() {
    this.initMap()
    this.subscribeEventEmitter()
  }

  subscribeEventEmitter() {
    EventEmitter.subscribe(Events.MAP_SHOW_TRAYECTORY, (event: any) => {
      this.map.showTrajectory(event.landmark)
    })

    EventEmitter.subscribe(Events.MAP_CLOSE_POPUP, () => {
      this.map.popup?.remove()
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {landmark, geolocateActive, language, landmarks, showDrawer} = this.props

    if(this.map) {
      this.map.geolocateActive = geolocateActive
      this.map.language = language

      if(landmarks.length != prevProps.landmarks.length || this.hasChangePropLandmarks(prevProps.landmarks, landmarks)) {
        this.updateLandmarks()
      }
    }

    if((prevProps.landmark === null && landmark !== null) ||
      (prevProps.landmark && landmark && prevProps.landmark.index !== landmark.index)) {

      if(window.innerWidth <= Constants.RESOLUTION_MOVIL) {
        this.map.flyToLandmark(landmark, 0)
        showDrawer(<InfoWindowMobil landmark={landmark}/>, 'left')
      } else {
        this.map.createPopUpLandMarker(landmark)
        this.map.flyToLandmark(landmark, landmark.sidebar ? 220 : 120)
        this.addEventShowTrayectory(landmark)
      }
    }
  }

  hasChangePropLandmarks(prevLandmarks: LandmarkType[], nextLandmarks: LandmarkType[]) {
    for (let i = 0; i < prevLandmarks.length; i++) {
      if(prevLandmarks[i].index != nextLandmarks[i].index || prevLandmarks[i].title != nextLandmarks[i].title) {
        return true
      }
    }

    return false
  }

  initMap() {
    const {
      handleGeolocate,
      geolocateActive,
      handleLandmarkSelected,
      hideDrawer,
      geolocatePerfil,
      handleViewPerfil,
      enqueueSnackbar
    } = this.props

    if(this.mapContainer) {
      this.map.mapContainer = this.mapContainer
      this.map.initMap()
      this.map.geolocateActive = geolocateActive
      this.map.handleGeolocate = handleGeolocate
      this.map.handleLandmarkSelected = handleLandmarkSelected
      this.map.hideDrawer = hideDrawer
      this.map.perfil = geolocatePerfil
      this.map.handleViewPerfil = handleViewPerfil
      this.map.enqueueSnackbar = enqueueSnackbar
    }
  }

  updateLandmarks() {
    const {landmarks} = this.props

    this.map.updateLandmarks(landmarks)
  }

  addEventShowTrayectory(landmark: LandmarkType) {
    const element = document.getElementById(`btn-show-trajectory-${landmark.index}`)
    const onClick = () => this.map.showTrajectory(landmark)

    if(element) {
      element.removeEventListener('click', onClick)
      element.addEventListener('click', onClick)
    }
  }

  handleClickPerfil(perfil: string) {
    const {handleChangePerfil} = this.props

    this.map.perfil = perfil
    this.map.reloadTrajectory()

    handleChangePerfil(perfil)
  }

  handleClickCloseRoute = () => {
    this.map.closeRoute()
  }

  handleDownloadPdf = () => {
    FileSaver.saveAs('/MiBsAs_MapaFrente_Nro14_GCBA.pdf', 'MiBsAs_MapaFrente_Nro14_GCBA.pdf')
  }

  render() {
    const {language, showDrawer, spinner, geolocatePerfil, geolocateViewChangePerfil} = this.props

    return (
      <div className="position-relative container-content">
        {spinner && <LinearProgress/>}

        <div ref={el => this.mapContainer = el} className="mam-container"/>

        <Button className="btn-references " appearance="primary" onClick={() => showDrawer(<References/>)}>
          {language.dictionary['REFERENCES']}
        </Button>

        {
          window.innerWidth < Constants.RESOLUTION_MOVIL ? (
            <IconButton
              className="btn-download-pdf"
              appearance="primary"
              onClick={this.handleDownloadPdf}
              style={{left: '25px'}}
              circle
              icon={<Icon icon="cloud-download"/>}
            />
          ) : (
            <Button
              className="btn-download-pdf"
              appearance="primary"
              onClick={this.handleDownloadPdf}
              style={{left: '285px'}}
            >
              <Icon icon="cloud-download" className="m-r-5"/>
              <span>{language.dictionary['DESCARGAR']}</span>
            </Button>)
        }

        {
          geolocateViewChangePerfil && (
            <ButtonGroup vertical className="map-profile">
              <Button
                appearance={geolocatePerfil === 'walking' ? 'primary' : 'default'}
                onClick={() => this.handleClickPerfil('walking')}>
                <FontAwesomeIcon icon={faWalking}/>
              </Button>

              <Button
                appearance={geolocatePerfil === 'cycling' ? 'primary' : 'default'}
                onClick={() => this.handleClickPerfil('cycling')}>
                <FontAwesomeIcon icon={faBiking}/>
              </Button>

              <Button
                appearance={geolocatePerfil === 'driving' ? 'primary' : 'default'}
                onClick={() => this.handleClickPerfil('driving')}>
                <FontAwesomeIcon icon={faCar}/>
              </Button>

              <Button
                appearance={'default'}
                onClick={this.handleClickCloseRoute}
              >
                <FontAwesomeIcon icon={faTimes}/>
              </Button>
            </ButtonGroup>
          )
        }
      </div>
    )
  }
}

type Props = {
  language: LanguageType
  showDrawer: (component: any, placement?: string) => void
  hideDrawer: () => void,
  spinner: boolean
  geolocateActive: boolean
  geolocateViewChangePerfil: boolean
  landmarks: LandmarkType[]
  landmark: LandmarkType | null
  handleLandmarkSelected: (landmark: LandmarkType | null) => void
  handleChangePerfil: (perfil: string) => void,
  handleGeolocate: (active: boolean) => void,
  handleViewPerfil: (view: boolean) => void,
  geolocatePerfil: string
}

const mapStateToProps = (state: StateType) => {
  return {
    language: state.language,
    spinner: state.landmarks.spinner,
    landmarks: state.landmarks.data,
    landmark: state.landmarks.landmark,
    geolocateActive: state.geolocate.active,
    geolocatePerfil: state.geolocate.perfil,
    geolocateViewChangePerfil: state.geolocate.view,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showDrawer: (component: any, placement = 'right') => {
      dispatch(showDrawer(component, placement))
    },
    hideDrawer: () => {
      dispatch(hideDrawer())
    },
    handleLandmarkSelected: (landmark: LandmarkType | null) => {
      dispatch(landmarkSelected(landmark))
    },
    handleGeolocate: (active: boolean) => {
      dispatch(active ? geolacateActive() : geolacateDisabled())
    },
    handleChangePerfil: (perfil: string) => {
      dispatch(geolacateChangePerfil(perfil))
    },
    handleViewPerfil: (view: boolean) => {
      dispatch(geolacateViewPerfil(view))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Content))
