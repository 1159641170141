import {LandmarkType} from '../types/LandmarkType'
import HttpClient from '../commons/HttpClient'
import {Endpoints} from '../commons/Endpoints'
import {Constants} from '../config/Constants'
import IPlace from '../interfaces/IPlace'
import IImage from '../interfaces/IImage'
import LocalStorage from '../commons/LocalStorage'
import ICategory from '../interfaces/ICategory'
import ITag from '../interfaces/ITag'

export const LANDMARKS_LOAD = 'LANDMARKS_LOAD'
export const LANDMARKS_SUCCESS = 'LANDMARKS_SUCCESS'
export const LANDMARKS_ERROR = 'LANDMARK_ERROR'
export const LANDMARK_SELECTED = 'LANDMARK_SELECTED'
export const LANDMARKS_SEARCH = 'LANDMARKS_SEARCH'

/*
 ejemplo de imagen
 "1-imagenprueba.jpg"

 tipos de imagenes
 1. original
 2. thumbnail
 3. default
 * */
const getLogo = (images: IImage[]) => {
  if(images.length) {
    const image = images[0]

    const filename = image.attributes.filename
    let url = image.attributes.url.replace(filename, '')
    const split = filename.split('-')

    split.forEach((part, i) => {
      if(i == 0) {
        url += '2'
      } else {
        url += `-${part}`
      }
    })

    return url
  }

  return null
}
const getLogoCategory = (categories: ICategory[]) => {
  if(categories.length) {
    const category = categories[0]

    if(category.relationships.icon){
      const url = category.relationships.icon.attributes.url

      return url
    }
  }

  return null
}

const isSidebar = (categories: ICategory[]) => {
  const exist = categories.find(category => category.id === Constants.CATEGORY_LANDMARK)

  return !!exist
}

export function landmarksLoadInit() {
  return (dispatch: any) => {
    dispatch(landmarksLoad())

    const lang:any = LocalStorage.getItem('language')!

    HttpClient.get(
      Endpoints.GET_PLACES,
      {
        lang,
        status: Constants.PLACES.PUBLIC,
        per_page: -1
      },
      (response: any) => {
        console.log(response.data.data)
        let places: IPlace[] = response.data.data
        const points: LandmarkType[] = []

        places = places.filter(place => {
          const geometries = place.relationships.geometries

          if(isSidebar(place.relationships.categories)){
            const logo = getLogo(place.relationships.images)

            return logo && geometries.length > 0
          }

          return geometries.length > 0
        })

        places = places.sort((a, b) => {
          const aIsSidebar = isSidebar(a.relationships.categories)
          const bIsSidebar = isSidebar(b.relationships.categories)

          if(aIsSidebar == bIsSidebar) {
            return 0
          }

          return aIsSidebar ? -1 : 1
        })

        places.forEach((place, i) => {
          let logo: any
          let img: any = null
          const geometries = place.relationships.geometries
          const sidebar = isSidebar(place.relationships.categories)

          if(sidebar){
            logo = getLogo(place.relationships.images)
            img = logo ? place.relationships.images[0].attributes.url : null
          }else{
            logo = getLogoCategory(place.relationships.categories)
          }

          if(logo) {
            points.push({
              index: i + 1,
              title: place.attributes.name ? place.attributes.name : '',
              description: place.attributes.description ? place.attributes.description : '',
              tags: place.relationships.tags && place.relationships.tags[lang] ? place.relationships.tags[lang].map((item: ITag) => item.name) : [],
              lat: geometries[0].lat,
              lng: geometries[0].lng,
              text: place.attributes.description ? place.attributes.description : '',
              logo,
              img ,
              address: place.relationships.geometries[0].address ? place.relationships.geometries[0].address : '',
              sidebar: isSidebar(place.relationships.categories)
            })
          }
        })

        console.log(points)
        dispatch(landmarksSuccess(points))
      },
      (errors: any) => {
        dispatch(landmarksErrors(errors))
      }
    )
  }
}

export function landmarksLoad() {
  return {
    type: LANDMARKS_LOAD,
    payload: {}
  }
}

export function landmarksSuccess(data: any[]) {
  return {
    type: LANDMARKS_SUCCESS,
    payload: {
      data
    }
  }
}

export function landmarksErrors(errors: any) {
  return {
    type: LANDMARKS_ERROR,
    payload: {
      errors
    }
  }
}

export function landmarkSelected(landmark: LandmarkType | null) {
  return {
    type: LANDMARK_SELECTED,
    payload: {
      landmark
    }
  }
}

export function landmarksSearch(search: string) {
  return {
    type: LANDMARKS_SEARCH,
    payload: {search}
  }
}
