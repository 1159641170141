export const Config = {
  END_POINT: 'https://api.mibsas.net/api',
  MAPBOX_KEY: 'pk.eyJ1IjoibWZlcm5hbmRlemJlcnRvbGEiLCJhIjoiY2traGYwODJoMGFnejJucWtuMDR2OGNuZSJ9.8OH0zlYKYhRZtChfCwXSIA',
  MAPBOX_STYLES: 'mapbox://styles/mfernandezbertola/ckkhf9hh61agl17mpssdg6dfy',
  MAPBOX_API_DIRECTIONS: 'https://api.mapbox.com/directions/v5/',
  MAPBOX_CENTER: {
    lng: -58.43543898068756,
    lat: -34.60620585594086
  },
  MAPBOX_ZOOM: 13
}
