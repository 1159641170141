import { combineReducers } from 'redux'
import language from './language'
import showDrawer from './show-drawer'
import landmarks from './landmarks'
import geolocate from './geolocate'

const reducers = {
  language,
  showDrawer,
  landmarks,
  geolocate
}

export default combineReducers(reducers)
