import React from 'react'
import {LandmarkType} from '../../types/LandmarkType'
import {Col, Row} from 'react-bootstrap'
import {Button, Divider} from 'rsuite'
import { useSelector } from 'react-redux'
import {StateType} from '../../types/StateType'
import EstampitaVamosBuenosAires from '../../asset/img/stampita-vamos-buenos-aires.svg'
import VamosBuenosAires from '../../asset/img/vamos-buenos-aires.svg'
import EventEmitter from '../../commons/EventEmitter'
import {Events} from '../../config/Events'

const InfoWindowMobil = (props: Props) => {
  const {landmark} = props
  const dictionary = useSelector((state: StateType) => state.language.dictionary)

  const handleClick = () => {
    EventEmitter.dispatch(Events.MAP_SHOW_TRAYECTORY, {landmark})
  }

  return (
    <div className="infowindow mobil">
      <Row className="m-0">
        <Col lg="auto" md="auto" sm="auto" xl="auto" xs="auto" className="p-0" style={{width:'70px'}}>
          <div className="marker-landmark cursor mapboxgl-marker mapboxgl-marker-anchor-center">
            <div className="rs-avatar img rs-avatar-lg rs-avatar-circle">
              <img className="rs-avatar-image" src={landmark.logo} width="100%"/>
            </div>
            {landmark.sidebar && <div className="rs-avatar ref rs-avatar-sm rs-avatar-circle">{landmark.index}</div>}
          </div>
        </Col>
        <Col className="p-0 title">
          <p className="text-center">{landmark.title}</p>
          <Divider className="m-l-0 m-r-0 m-t-0 m-b-30"/>
        </Col>
      </Row>

      {landmark.sidebar &&
        <Row className="m-0">
          <Col className="p-0 img">
            <img src={landmark.img}/>
          </Col>
        </Row>
      }

      <Row className="m-l-0 m-r-0 m-t-10">
        <Col className="p-0 description">
          <div dangerouslySetInnerHTML = {{__html: landmark.text}} />
        </Col>
      </Row>

      <Row className="m-l-0 m-r-0 m-t-10">
        <Col className="p-0 description">
          <b>{dictionary['DIRECCION']}:</b> {landmark.address}
        </Col>
      </Row>

      <Row className="m-l-0 m-r-0 m-t-30 m-b-60">
        <Col className="p-0 justify-content-center text-center logos-header">
          <img src={EstampitaVamosBuenosAires} height={40} className="m-l-10 m-r-10"/>
          <img src={VamosBuenosAires} height={40} className="m-l-10 m-r-10"/>
        </Col>
      </Row>

      <Button appearance="primary" size="sm" style={{position:'fixed', right: '25px', bottom: '25px'}} onClick={handleClick}>
        {dictionary['COMO_LLEGAR']}
      </Button>
    </div>
  )
}

type Props = {
  landmark: LandmarkType
}

export default InfoWindowMobil
