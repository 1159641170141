import React from 'react'
import {Avatar, Icon, Input, InputGroup} from 'rsuite'
import {Col, Row} from 'react-bootstrap'
import Scrollbar from 'react-scrollbars-custom'
import {StateType} from '../../types/StateType'
import {connect} from 'react-redux'
import {LanguageType} from '../../types/LanguageType'
import {LandmarkType} from '../../types/LandmarkType'
import Skeleton from '@material-ui/lab/Skeleton'
import {landmarkSelected, landmarksSearch} from '../../actions/landmarks'

let timeout: any = null

const Sidebar = (props: Props) => {
  const {language, mobil, points, spinner, handleLandmarkSelected, search, handleLandmarksSearch} = props
  const dataSpinner: any[] = []

  const handleClick = (landmark: LandmarkType) => {
    handleLandmarkSelected(landmark)
  }


  const renderPoint = () => {
    return points
      .filter((landmark: LandmarkType) => landmark.sidebar && (landmark.title.toUpperCase().indexOf(search.toUpperCase()) >= 0 || landmark.description.toUpperCase().indexOf(search.toUpperCase()) >= 0 || landmark.tags.map((x: string)=> { return x.toUpperCase() }).indexOf(search.toUpperCase()) >= 0))
      .map((landmark: LandmarkType, i: number) => (
        <Row key={i} className="m-l-0 m-r-0 m-t-8 align-items-center">
          <Col lg="auto" md="auto" sm="auto" xl="auto" xs="auto" className="p-0">
            <Avatar circle className="primary cursor" onClick={() => handleClick(landmark)}>
              {landmark.index}
            </Avatar>
          </Col>
          <Col className="p-r-0 p-l-10 cursor">
            <span onClick={() => handleClick(landmark)}>{landmark.title}</span>
          </Col>
        </Row>
      ))
  }

  const addDataSpinner = (index: number) => {
    const element = <Row key={index} className="m-l-0 m-r-0 m-t-8 align-items-center">
      <Col lg="auto" md="auto" sm="auto" xl="auto" xs="auto" className="p-0">
        <Skeleton variant="circle" width={40} height={40} animation="wave"/>
      </Col>
      <Col className="p-r-0 p-l-10 cursor">
        <Skeleton variant="text" animation="wave"/>
      </Col>
    </Row>

    dataSpinner.push(element)
  }

  for (let i = 0; i < 3; i++) {
    addDataSpinner(i)
  }

  const handleOnChange = (value: string) => {
    if(timeout !== null){
      clearTimeout(timeout)
    }

    timeout = setTimeout(()=>handleLandmarksSearch(value), 300)
  }

  return (
    <Row className="sidebar m-0">
      <Col className="p-t-15 p-l-15 p-r-5 p-b-15">

        <Row className="m-l-0 m-r-0 m-b-14">
          <Col className="p-0">
            <InputGroup inside>
              <Input placeholder={language.dictionary['INPUT_BUSCAR']} onChange={handleOnChange} defaultValue={search}/>

              <InputGroup.Addon>
                <Icon icon="search"/>
              </InputGroup.Addon>
            </InputGroup>
          </Col>
        </Row>

        <Row className="m-0">
          <Col className="p-0">
            {
              spinner ? (
                dataSpinner
              ):(
                <>
                  {
                    mobil ? (
                      renderPoint()
                    ) : (
                      <Scrollbar style={{height: 'calc(100vh - 70px)'}}>
                        {renderPoint()}
                      </Scrollbar>
                    )
                  }
                </>
              )
            }
          </Col>
        </Row>

      </Col>
    </Row>
  )
}

type Props = {
  language: LanguageType,
  mobil?: boolean,
  points: LandmarkType[],
  spinner: boolean
  search: string
  handleLandmarkSelected: (landmark: LandmarkType | null) => void
  handleLandmarksSearch: (search: string) => void
}

const mapStateToProps = (state: StateType) => {
  return {
    language: state.language,
    points: state.landmarks.data,
    spinner: state.landmarks.spinner,
    search: state.landmarks.search,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleLandmarkSelected: (landmark: LandmarkType | null) => {
      dispatch(landmarkSelected(landmark))
    },
    handleLandmarksSearch: (search: string) => {
      dispatch(landmarksSearch(search))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
