import Axios from 'axios'
import {Config} from '../config/Config'

const HttpClient = {
  getHeaders: () => {
    const headers: any = {
      'Access-Control-Allow-Origin': '*',
    }

    return headers
  },

  get: (pathRelative: string, data: any, success?: any, error?: any, always?: any) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios.get(Config.END_POINT + pathRelative, {
      params: data,
      cancelToken: source.token,
      headers: HttpClient.getHeaders(),
    })
      .then(function (response) {
        if(success) {
          success(response)
        }
      })
      .catch(function (errors) {
        if(errors.response && error) {
          error(errors)
        }
      })
      .then(function () {
        if(always) {
          always()
        }
      })

    return source
  },

  getExternal: (path: string, data: any, success?: any, error?: any, always?: any) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios.get(path, {
      params: data,
      cancelToken: source.token,
      headers: HttpClient.getHeaders(),
    })
      .then(function (response) {
        if(success) {
          success(response)
        }
      })
      .catch(function (errors) {
        if(errors.response && error) {
          error(errors)
        }
      })
      .then(function () {
        if(always) {
          always()
        }
      })

    return source
  },

  post: (pathRelative: string, data: any, success?: any, error?: any, always?: any) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios({
      method: 'post',
      cancelToken: source.token,
      url: pathRelative,
      data: data,
      baseURL: Config.END_POINT,
      headers: HttpClient.getHeaders(),
    })
      .then(function (response) {
        if(success) {
          success(response)
        }
      })
      .catch(function (errors) {
        if(error) {
          error(errors)
        }
      })
      .then(function () {
        if(always) {
          always()
        }
      })

    return source
  },

  put: (pathRelative: string, data: any, success?: any, error?: any, always?: any) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios({
      method: 'put',
      cancelToken: source.token,
      url: pathRelative,
      data: data,
      baseURL: Config.END_POINT,
      headers: HttpClient.getHeaders(),
    })
      .then(function (response) {
        if(success) {
          success(response)
        }
      })
      .catch(function (errors) {
        if(error) {
          error(errors)
        }
      })
      .then(function () {
        if(always) {
          always()
        }
      })

    return source
  },

  delete: (pathRelative: string, data: any, success?: any, error?: any, always?: any) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios({
      method: 'delete',
      cancelToken: source.token,
      url: pathRelative,
      data: data,
      baseURL: Config.END_POINT,
      headers: HttpClient.getHeaders(),
    })
      .then(function (response) {
        if(success) {
          success(response)
        }
      })
      .catch(function (errors) {
        if(error) {
          error(errors)
        }
      })
      .then(function () {
        if(always) {
          always()
        }
      })

    return source
  }
}
export default HttpClient
