import {Config} from '../config/Config'
import {LandmarkType} from '../types/LandmarkType'
import {LanguageType} from '../types/LanguageType'
import HttpClient from '../commons/HttpClient'
import {PositionType} from '../types/PositionType'
import {Functions} from '../commons/Functions'
// @ts-ignore
import mapboxgl from '!mapbox-gl'

export default class Map {
  get enqueueSnackbar(): any {
    return this._enqueueSnackbar
  }

  set enqueueSnackbar(value: any) {
    this._enqueueSnackbar = value
  }
  get handleViewPerfil(): (view: boolean) => void {
    return this._handleViewPerfil
  }

  set handleViewPerfil(value: (view: boolean) => void) {
    this._handleViewPerfil = value
  }
  get start(): [number, number] {
    return this._start
  }

  set start(value: [number, number]) {
    this._start = value
  }

  get end(): [number, number] {
    return this._end
  }

  set end(value: [number, number]) {
    this._end = value
  }

  get perfil(): string {
    return this._perfil
  }

  set perfil(value: string) {
    this._perfil = value
  }

  get hideDrawer(): () => void {
    return this._hideDrawer
  }

  set hideDrawer(value: () => void) {
    this._hideDrawer = value
  }

  get popup(): mapboxgl.Popup | null {
    return this._popup
  }

  set popup(value: mapboxgl.Popup | null) {
    this._popup = value
  }

  get currentPosition(): PositionType {
    return this._currentPosition
  }

  set currentPosition(value: PositionType) {
    this._currentPosition = value
  }

  get canvas(): any {
    return this._canvas
  }

  set canvas(value: any) {
    this._canvas = value
  }

  get language(): LanguageType {
    return this._language
  }

  set language(value: LanguageType) {
    this._language = value
  }

  get handleLandmarkSelected(): (landmark: (LandmarkType | null)) => void {
    return this._handleLandmarkSelected
  }

  set handleLandmarkSelected(value: (landmark: (LandmarkType | null)) => void) {
    this._handleLandmarkSelected = value
  }

  get landmarks(): mapboxgl.Marker[] {
    return this._landmarks
  }

  set landmarks(value: mapboxgl.Marker[]) {
    this._landmarks = value
  }

  get handleGeolocate(): ((active: boolean) => void) {
    return this._handleGeolocate
  }

  set handleGeolocate(value: (active: boolean) => void) {
    this._handleGeolocate = value
  }

  get geolocateActive(): boolean {
    return this._geolocateActive
  }

  set geolocateActive(value: boolean) {
    this._geolocateActive = value
  }

  get map(): mapboxgl.Map {
    return this._map
  }

  set map(value: mapboxgl.Map) {
    this._map = value
  }

  get geolocate(): mapboxgl.GeolocateControl {
    return this._geolocate
  }

  set geolocate(value: mapboxgl.GeolocateControl) {
    this._geolocate = value
  }

  get mapContainer(): any {
    return this._mapContainer
  }

  set mapContainer(value: any) {
    this._mapContainer = value
  }

  private _map!: mapboxgl.Map
  private _geolocate!: mapboxgl.GeolocateControl
  private _mapContainer: any
  private _geolocateActive = false
  private _handleGeolocate!: (active: boolean) => void
  private _landmarks: mapboxgl.Marker[] = []
  private _handleLandmarkSelected!: (landmark: LandmarkType | null) => void
  private _handleViewPerfil!: (view: boolean) => void
  private _hideDrawer!: () => void
  private _language!: LanguageType
  private _canvas: any
  private _currentPosition!: PositionType
  private _popup!: mapboxgl.Popup | null
  private _perfil!: string
  private _start!: [number, number]
  private _end!: [number, number]
  private _enqueueSnackbar!: any

  constructor() {
    mapboxgl.accessToken = Config.MAPBOX_KEY
  }

  public initMap() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: Config.MAPBOX_STYLES,
      center: Config.MAPBOX_CENTER,
      zoom: Config.MAPBOX_ZOOM,
      pitch: 60,
      bearing: 30
    })
    this.canvas = this.map.getCanvasContainer()

    this.addBtnControls()
    this.addEventGeoLocate()
  }

  private addBtnControls() {
    if(this.map) {
      this.map.addControl(new mapboxgl.NavigationControl())

      this._geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })

      this.map.addControl(this._geolocate)
    }
  }

  private addEventGeoLocate() {
    if(this.map) {
      this.map.on('load', () => {
        this.geolocate.trigger()
        this.geolocate.on('trackuserlocationend', this.handleGeolocateEnd)
        this.geolocate.on('geolocate', this.handleGeolocateStart)
      })
    }
  }

  private handleGeolocateEnd = () => {
    this.handleGeolocate(false)
  }

  private handleGeolocateStart = (data: any) => {
    this.currentPosition = {
      lat: data.coords.latitude,
      lng: data.coords.longitude
    }

    if(!this.geolocateActive) {
      this.handleGeolocate(true)
    }
  }

  public addLandmark(landmark: LandmarkType) {
    const marker = new mapboxgl.Marker(this.getHtmlLandmarkMarker(landmark))
      .setLngLat([landmark.lng, landmark.lat])
      .addTo(this.map)

    this.landmarks.push(marker)
  }

  private removeMarkersNotExist(landmarks: LandmarkType[]) {
    for (let i = this.landmarks.length - 1; i >= 0; i--) {
      const marker = this.landmarks[i]
      const element = marker.getElement()
      const exist = landmarks.find(landmark => element.id == landmark.index.toString())

      if(!exist) {
        marker.remove()
        this.landmarks.splice(i, 1)
      }
    }
  }

  public updateLandmarks(landmarks: LandmarkType[]) {
    this.removeMarkersNotExist(landmarks)

    landmarks.reverse().forEach(landmark => {
      const exist = this.landmarks.find(marker => {
        const element = marker.getElement()
        return element.id == landmark.index.toString()
      })

      if(!exist) {
        this.addLandmark(landmark)
      }
    })
  }

  private getHtmlLandmarkMarker(landmark: LandmarkType) {
    const markerLandmark = document.createElement('div')
    markerLandmark.className = 'marker-landmark cursor' + (!landmark.sidebar ? ' small' : '')
    markerLandmark.id = landmark.index.toString()

    const avatarImg = document.createElement('div')
    avatarImg.className = 'rs-avatar img rs-avatar-circle' + (landmark.sidebar ? ' rs-avatar-lg' : ' rs-avatar-sm')

    const img = document.createElement('img')
    img.className = 'rs-avatar-image'
    img.src = landmark.logo

    avatarImg.appendChild(img)

    markerLandmark.append(avatarImg)

    if(landmark.sidebar) {
      const avatarRef = document.createElement('div')
      avatarRef.className = 'rs-avatar ref rs-avatar-sm rs-avatar-circle'
      avatarRef.innerText = landmark.index.toString()

      markerLandmark.append(avatarRef)
    }

    markerLandmark.addEventListener('click', () => {
      this.handleLandmarkSelected(landmark)
    })

    return markerLandmark
  }

  public flyToLandmark(landmark: LandmarkType, displace = 220) {
    this.map.flyTo({
      center: [landmark.lng, landmark.lat],
      offset:  new mapboxgl.Point(0, displace)
    })
  }

  public createPopUpLandMarker(landmark: LandmarkType) {
    if(this.popup) {
      this.popup.remove()
    }

    const offset = landmark.sidebar ? new mapboxgl.Point(-5, -33) : new mapboxgl.Point(0, -15)

    this.popup = new mapboxgl.Popup({
      offset: offset,
      maxWidth: '390px',
      closeOnClick: false
    })
      .setLngLat([landmark.lng, landmark.lat])
      .setHTML(this.getHtmlPopUpLandmarker(landmark))
      .addTo(this.map)

    this.popup.on('close', () => {
      this.handleLandmarkSelected(null)
      this.popup = null
    })
  }

  private getHtmlPopUpLandmarker(landmark: LandmarkType) {
    const ref = `
        <div class="rs-avatar ref rs-avatar-sm rs-avatar-circle">
            ${landmark.index}
        </div>
    `

    const img = `
        <div class="m-0 row">
            <div class="p-0 img col">
                <img src="${landmark.img}">
            </div>
        </div>
    `

    return (` 
      <div class="infowindow">
            <div class="m-0 row">
                <div style="width: 70px;" class="p-0 col-xl-auto col-lg-auto col-md-auto col-sm-auto col-auto">
                    <div class="marker-landmark cursor mapboxgl-marker mapboxgl-marker-anchor-center">
                        <div class="rs-avatar img rs-avatar-lg rs-avatar-circle">
                            <img class="rs-avatar-image" src="${landmark.logo}" width="100%">
                        </div>
                        ${landmark.sidebar ? ref : ''}
                    </div>
                </div>
                <div class="p-0 title col">
                    <p class="text-center">
                        ${landmark.title}
                    </p>
                    <div class="rs-divider m-l-0 m-r-0 m-t-0 m-b-30 rs-divider-horizontal"></div>
                </div>
            </div>
            <div class="m-0 body row">
                <div class="p-0 col">
                    ${landmark.sidebar ? img : ''}
                    <div class="m-l-0 m-r-0 m-t-10 row">
                        <div class="p-0 description col">
                            ${landmark.text}
                        </div>
                    </div>
                    <div class="m-l-0 m-r-0 m-t-10 row">
                        <div class="p-0 description col">
                            <b>${this.language.dictionary['DIRECCION']}:</b> ${landmark.address}
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="m-l-0 m-r-0 m-t-10 row">
            <div class="p-0 text-right col">
                <button id="btn-show-trajectory-${landmark.index}" type="button" class="rs-btn rs-btn-primary rs-btn-xs">
                    ${this.language.dictionary['COMO_LLEGAR']}
                    <span class="rs-ripple-pond">
                        <span class="rs-ripple"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    `)
  }

  public showTrajectory = (landmark: LandmarkType) => {
    if(!this.geolocateActive) {
      this.geolocate.trigger()
    } else {
      if(this.currentPosition) {
        this.requestGetRoute([this.currentPosition.lng, this.currentPosition.lat], [landmark.lng, landmark.lat])
      }
    }
  }

  public reloadTrajectory = () => {
    if(this.start && this.end) {
      this.requestGetRoute(this.start, this.end)
    }
  }

  private requestGetRoute(start: [number, number], end: [number, number]) {
    const path = `${Config.MAPBOX_API_DIRECTIONS}mapbox/${this.perfil}/${start[0]},${start[1]};${end[0]},${end[1]}`
    this.start = start
    this.end = end

    let lang = this.language.code

    if(lang=='pt') {
      lang = 'pt-BR'
    }

    HttpClient.getExternal(
      path,
      {
        geometries: 'geojson',
        access_token: Config.MAPBOX_KEY,
        language: lang
      },
      (response: any) => {
        const json = response.data
        const data = json.routes[0]
        const route = data.geometry.coordinates
        const geojson = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: route
          }
        }

        // if the route already exists on the map, reset it using setData
        if(this.map.getSource('route')) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.map.getSource('route').setData(geojson)
        } else { // otherwise, make a new request
          this.map.addLayer({
            id: 'route',
            type: 'line',
            source: {
              type: 'geojson', data: {
                type: 'Feature', properties: {}, geometry: {
                  type: 'LineString', coordinates: route,
                }
              }
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': '#5271FF',
              'line-width': 6,
              'line-opacity': 1
            }
          })
        }

        if(this.popup) {
          this.popup.remove()
        }

        this.hideDrawer()
        this.map.fitBounds([start, end], {padding: 100})
        this.handleViewPerfil(true)
      },
      (errors: any) => {
        this.handleViewPerfil(false)
        this.enqueueSnackbar(Functions.errorObject(errors), {variant: 'error'})
      }
    )
  }

  public closeRoute(){
    if(this.map.getSource('route')) {
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.map.getSource('route').setData(geojson)
    }

    this.handleViewPerfil(false)
  }
}
