import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {Divider} from 'rsuite'
import batours from '../../asset/img/references/batours.svg'
import busTuristico from '../../asset/img/references/bus-turistico.svg'
import biciTourBA from '../../asset/img/references/bici-tour-ba.svg'
import trekkingUrbano from '../../asset/img/references/trekking-urbano.svg'
import visitasGuiadasPie from '../../asset/img/references/visitas-guiadas-a-pie.svg'
import surfUrbano from '../../asset/img/references/surf-urbano.svg'
import estacionOmnibus from '../../asset/img/references/estacion-omnibus.svg'
import estacionFerrocarril from '../../asset/img/references/estacion-ferrocarril.svg'
import terminalFluvial from '../../asset/img/references/terminal-fluvial.svg'
import aeroparque from '../../asset/img/references/aeroparque.svg'
import letrasAH from '../../asset/img/references/letras-A-H.svg'
import rectanguloRosa from '../../asset/img/references/rectangulo-rosa.svg'
import metrobus from '../../asset/img/references/metrobus.svg'
import rectanguloMarron from '../../asset/img/references/rectangulo-marron.svg'
import ecobicis from '../../asset/img/references/ecobicis.svg'
import centroAtencion from '../../asset/img/references/centro-atencion.svg'
import ciclovias from '../../asset/img/references/ciclovias.svg'
import zonaComercial from '../../asset/img/references/zona-comercial.svg'
// import salidaInicialBusTuristico from '../../asset/img/references/salida-inicial-bus-turistico.svg'
// import estacionBusTuristico from '../../asset/img/references/estacion-bus-turistico.svg'
import poloGastronomico from '../../asset/img/references/polo-gastronomico.svg'
import vidaNocturna from '../../asset/img/references/vida-nocturna.svg'
// import recorridoBusTuristico from '../../asset/img/references/recorrido-bus-turistico.svg'
import tangueriaMilonga from '../../asset/img/references/tangueria-milonga.svg'
import mural from '../../asset/img/references/mural.png'
import {LanguageType} from '../../types/LanguageType'
import {StateType} from '../../types/StateType'
import {connect} from 'react-redux'

const References = (props: Props) => {
  const {dictionary} = props.language

  // const whatToDo = [
  //   {img: busTuristico, text: dictionary['BUS_TURISTICO']},
  //   {img: biciTourBA, text: dictionary['BICI_TOUR_BA']},
  //   {img: trekkingUrbano, text: dictionary['TREKKING_URBANO']},
  //   {img: visitasGuiadasPie, text: dictionary['VISITAS_GUIADAS_PIE']},
  //   {img: surfUrbano, text: dictionary['SURF_URVANO']},
  // ]

  const references = [
    {img: estacionOmnibus, text: dictionary['ESTACION_OMNIBUS'], width: 32},
    {img: terminalFluvial, text: dictionary['TERMINAL_FLUVIAL'], width: 32},
    {img: estacionFerrocarril, text: dictionary['ESTACION_FERROCARRIL'], width: 32},
    {img: aeroparque, text: dictionary['AEROPARQUE_J_NEWBERY'], width: 32},
    {img: letrasAH, text: dictionary['ESTACION_SUBTERRANEO'], width: 50},
    {img: rectanguloRosa, text: dictionary['SENDA_PEATONA'], width: 30},
    {img: metrobus, text: dictionary['METROBUS'], width: 36},
    {img: rectanguloMarron, text: dictionary['PASEO_DEL_BAJO'], width: 30},
    {img: ecobicis, text: dictionary['ESTACION_ECOBICIS'], width: 30},
    {img: centroAtencion, text: dictionary['CENTRO_ATENCION_TURISTA'], width: 30},
    {img: ciclovias, text: dictionary['CICLOVIAS'], width: 32},
    {img: zonaComercial, text: dictionary['ZONA_COMERCIAL'], width: 32},
    // {img: salidaInicialBusTuristico, text: dictionary['SALIDA_INICIAL_BUS_TURISTICO'], width: 32},
    // {img: estacionBusTuristico, text: dictionary['ESTACION_BUS_TURISTICO'], width: 32},
    {img: poloGastronomico, text: dictionary['POLO_GASTRONOMICO'], width: 32},
    {img: vidaNocturna, text: dictionary['VIDA_NOCTURNA'], width: 32},
    // {img: recorridoBusTuristico, text: dictionary['RECORRIDO_BUS_TURISTICO'], width: 59},
    {img: tangueriaMilonga, text: dictionary['TANQUERIA_MILONGA'], width: 32},
    {img: mural, text: dictionary['MURAL'], width: 32},
  ]

  return (
    <Row className="m-0 references">
      <Col className="p-0">

        <Row className="m-0">
          <Col className="p-l-20 p-r-20">

            <Row className="m-0 advertisements align-items-center">
              <Col lg="auto" md="auto" sm="auto" xl="auto" xs="auto">
                <img src={batours} width={60}/>
              </Col>
              <Col className="p-0">
                <h5>{dictionary['TURISMO']}</h5>
                <h5>{dictionary['EN_BARRIOS']}</h5>
              </Col>
            </Row>

            <Row className="m-l-0 m-r-0 m-t-25 advertisements align-items-center">
              <Col>
                <p>{dictionary['BATOURS_DESCRIPTION']}</p>
                <p className="m-t-15 m-b-15">
                  <a rel="noreferrer" href="https://turismo.buenosaires.gob.ar/es/turismo-en-barrios" target="_blank">
                    {dictionary['INGRESA_AQUI']}
                  </a>
                </p>
              </Col>
            </Row>

            {/*<Row className="m-l-0 m-r-0 m-t-20 m-b-16">*/}
            {/*  <Col className="p-0">*/}
            {/*    <h6 className="text-center">{dictionary['QUE_HACER_BUENOS_AIRES']}</h6>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/**/}
            {/*{*/}
            {/*  whatToDo.map((item, i) => (*/}
            {/*    <Row key={i} className="m-l-0 m-r-0 m-b-10 align-items-center">*/}
            {/*      <Col lg="auto" md="auto" sm="auto" xl="auto" xs="auto">*/}
            {/*        <img src={item.img} width={45}/>*/}
            {/*      </Col>*/}
            {/*      <Col className="p-0">*/}
            {/*        <h6>{item.text}</h6>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*  ))*/}
            {/*}*/}
          </Col>
        </Row>

        <Row className="m-0">
          <Col className="p-0">
            <Divider className="m-t-10"/>
          </Col>
        </Row>

        <Row className="m-0 align-items-center">
          {
            references.map((item, i) => (
              <Col key={i} lg={6} md={6} sm={6} xl={6} xs={6} className="p-0 m-b-5">
                <Row className="m-0 align-items-center">
                  <Col lg="auto" md="auto" sm="auto" xl="auto" xs="auto" className="p-0">
                    <img src={item.img} width={item.width}/>
                  </Col>
                  <Col>
                    <span className="fs-6">{item.text}</span>
                  </Col>
                </Row>
              </Col>
            ))
          }
        </Row>
      </Col>
    </Row>
  )
}

type Props = {
  language: LanguageType
}

const mapStateToProps = (state: StateType) => {
  return {
    language: state.language
  }
}

export default connect(mapStateToProps)(References)
