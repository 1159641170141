import React from 'react'
import {
  Container as ContainerRsuite,
  Header as HeaderRsuite,
  Content as ContentRsuite,
  Sidebar as SidebarRsuite
} from 'rsuite'
import './App.css'
import './asset/scss/style.scss'
import 'rsuite/dist/styles/rsuite-default.css'
import Sidebar from './components/sidebar/Sidebar'
import Header from './components/header/Header'
import Content from './components/content/Content'
import ShowDrawer from './components/show-drawer/ShowDrawer'
import {SnackbarProvider} from 'notistack'

const App = () => {
  return (
    <ContainerRsuite>
      <ContainerRsuite>
        <SidebarRsuite className="d-none d-md-none d-sm-none d-lg-block">
          <Sidebar/>
        </SidebarRsuite>
        <ContainerRsuite>
          <HeaderRsuite>
            <Header/>
          </HeaderRsuite>
          <ContentRsuite>
            <SnackbarProvider maxSnack={10}>
              <Content/>
            </SnackbarProvider>
            <ShowDrawer/>
          </ContentRsuite>
        </ContainerRsuite>
      </ContainerRsuite>
    </ContainerRsuite>
  )
}

export default App
