import React from 'react'
import {Dropdown, Popover} from 'rsuite'
import {Constants} from '../../config/Constants'
import Language from './Language'
import {LanguageType} from '../../types/LanguageType'

const {Menu, Item} = Dropdown

type Props = {
  onSelect: (eventKey: string, event: any) => void
}

const MenuPopover = (props: Props) => {
  const {onSelect, ...rest} = props

  return (
    <Popover {...rest} full>
      <Menu onSelect={onSelect}>
        {
          Constants.LANGUAGES.map((item: LanguageType, i: number) => <Item key={i} eventKey={item.code}>
            <Language flag={item.flag} slug={item.slug}/>
          </Item>)
        }
      </Menu>
    </Popover>
  )
}

export default MenuPopover
