import {Constants} from '../config/Constants'
import {LanguageType} from '../types/LanguageType'

export const Functions = {
  getLanguage(code: string): LanguageType | undefined {
    return Constants.LANGUAGES.find(item => item.code == code)
  },
  errorObject: (errors: any) => {
    let description = 'Ha ocurrido un error'

    if (
      errors.response &&
      errors.response.data &&
      errors.response.data.message
    ) {
      description = errors.response.data.message
    } else if (errors.response && errors.response.message) {
      description = errors.response.message
    } else if (errors.message) {
      description = errors.message
    }

    return description
  },
}
