import {LanguageType} from '../types/LanguageType'
import es from '../i18n/es.json'
import en from '../i18n/en.json'
import pt from '../i18n/pt.json'
import LocalStorage from '../commons/LocalStorage'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export function changeLanguage(language: LanguageType) {
  let dictionary: any = es

  switch (language.code) {
  case 'en':
    dictionary = en
    break
  case 'pt':
    dictionary = pt
    break
  }

  LocalStorage.setItem('language', language.code)

  return {
    type: CHANGE_LANGUAGE,
    traducciones: {
      language,
      dictionary
    }
  }
}
