import React from 'react'
import {Col, Row} from 'react-bootstrap'
import Languages from '../language/Languages'
import VamosBuenosAires from '../../asset/img/vamos-buenos-aires.svg'
import EstampitaVamosBuenosAires from '../../asset/img/stampita-vamos-buenos-aires.svg'
import {Icon} from 'rsuite'
import {showDrawer} from '../../actions/show-drawer'
import {connect} from 'react-redux'
import Sidebar from '../sidebar/Sidebar'

const Header = (props: Props) => {
  const {showDrawer} = props

  return (
    <Row className="m-0 align-items-center header m-t-10 p-b-10">
      <Col className="p-0 justify-content-center text-center logos-header d-none d-md-none d-sm-none d-lg-block">
        <img src={EstampitaVamosBuenosAires} height={40} className="m-l-40 m-r-40"/>
        <img src={VamosBuenosAires} height={40} className="m-l-40 m-r-40"/>
      </Col>

      <Col lg="auto" md="auto" sm="auto" xl="auto" xs="auto"
        className="p-l-15 p-r-0 d-block d-lg-none d-xl-none d-xxl-none">
        <Icon icon="bars" size="2x" className="cursor" onClick={showDrawer}/>
      </Col>

      <Col className="p-0 justify-content-center text-center logos-header d-block d-lg-none d-xl-none d-xxl-none">
        <img src={EstampitaVamosBuenosAires} height={30} className="m-l-10 m-r-10"/>
        <img src={VamosBuenosAires} height={30} className="m-l-10 m-r-10"/>
      </Col>

      <Col lg="auto" md="auto" sm="auto" xl="auto" xs="auto" className="p-0">
        <Languages/>
      </Col>
    </Row>
  )
}

type Props = {
  showDrawer: () => void
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showDrawer: () => {
      dispatch(showDrawer(<Sidebar mobil={true}/>, 'left'))
    }
  }
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
