import React from 'react'
import {connect} from 'react-redux'
import {StateType} from '../../types/StateType'
import Drawer from '../drawer/Drawer'
import {hideDrawer} from '../../actions/show-drawer'
import {Constants} from '../../config/Constants'

const ShowDrawer = (props: Props) => {
  const {showDrawer, closeDrawer, component, placement} = props
  const size = 'xs'
  const full = window.innerWidth <= Constants.RESOLUTION_MOVIL

  return (
    <Drawer
      size={size}
      full={full}
      backdrop={false}
      show={showDrawer}
      body={component}
      placement={placement}
      close={closeDrawer}
      className={placement !== 'left' ? 'background-black' : ''}
    />
  )
}

type Props = {
  showDrawer: boolean
  closeDrawer: () => void
  placement: string
  component: any
}

function mapStateToProps(state: StateType) {
  return {
    showDrawer: state.showDrawer.show,
    placement: state.showDrawer.placement,
    component: state.showDrawer.component
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDrawer: () => {
      dispatch(hideDrawer())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowDrawer)
