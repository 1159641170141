import {HIDE_DRAWER, SHOW_DRAWER} from '../actions/show-drawer'

const initialState = {
  show: false,
  placement: 'right',
  component: null
}

export default (state = initialState, action: any) => {
  switch (action.type) {
  case SHOW_DRAWER:
    return {
      ...state,
      show: action.payload.show,
      placement: action.payload.placement,
      component: action.payload.component
    }
  case HIDE_DRAWER:
    return {
      ...state,
      show: action.show,
      component: null
    }
  default:
    return state
  }
}
